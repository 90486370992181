import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useTranslation } from "@ca-dmv-radv/translation";
import {
  useApplication,
  useGoogleAnalytics,
  setLanguage,
} from "@ca-dmv-radv/data";

function WrappedComponent({ eventName, title, children }) {
  const { i18n } = useTranslation();
  const applicationData = useApplication();
  const { pathname } = useLocation();
  const { analyticsAction, trackEvent } = useGoogleAnalytics();

  const application = applicationData?.application;

  /**
   * Update the application and document language when the route changes.
   */
  useEffect(() => {
    (async () => {
      if (application?.lang) {
        // Set application language
        i18n.changeLanguage(application?.lang);
        // Set language for API calls
        await setLanguage(application?.lang);
        // Update page language attribute
        document.documentElement.lang = application?.lang;
      }
    })();
  }, [application?.lang]);

  /**
   * Update the document title when route changes.
   */
  useEffect(() => {
    document.title = title;
  }, [title]);

  /**
   * Scroll to top when location changes.
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  /**
   * Track the event for the current screen.
   */
  useEffect(() => {
    trackEvent(
      eventName + analyticsAction.replace(/ /g, ""),
      `${title} - ${analyticsAction}`
    );
  }, [analyticsAction, title]);

  return children;
}

function App({ routes }) {
  return (
    <Routes>
      {routes.map(({ path, title, Component, eventName }) => (
        <Route
          path={path}
          key={path}
          element={
            <WrappedComponent title={title} eventName={eventName}>
              <Component title={title} />
            </WrappedComponent>
          }
        />
      ))}
    </Routes>
  );
}

export default App;
