import React, { useMemo, useState } from "react";
import {
  Button,
  ICON_TRASH,
  BUTTON_ICON_LEFT,
  BUTTON_STYLE_LINK__ICON,
} from "@ca-dmv/core";
import { useTranslation } from "@ca-dmv-radv/translation";
import { formatName } from "@ca-dmv-radv/utilities";
import {
  NameConfirmationCard,
  IDENTITY_DOCUMENT,
  NAME_CHANGE_DOCUMENT,
  CARD_PROOF_OF_IDENTITY,
  NCH_NAME_CHANGE_DOCUMENT,
} from "@ca-dmv-radv/components";
import RemoveNameCardModal from "./RemoveNameCardModal";
import { APPLICATION_TYPE, useApplication } from "@ca-dmv-radv/data";
import { isApplicationNch } from "../constants";

function Card({ card, index, nameCards, setCatDocId, setNameChangeToRemove }) {
  const { t } = useTranslation();
  const { applicationType } = useApplication();
  const isApplicationName = index >= nameCards.length - 1;
  const legalNameHeading = () => {
    if (isApplicationName) {
      if (isApplicationNch(applicationType)) {
        return t(
          "component-identity-name-on-application",
          "Name on Application"
        );
      } else {
        return t(
          "component-identity-application-prior-legal-name",
          "Current Legal Name"
        );
      }
    }
    else {
      return t(
        "component-identity-application-current-legal-name",
        "Prior Legal Name"
      )
    }
  }
  return (
    <div key={card.id} className="w--100 mt-24">
      <NameConfirmationCard
        cardType={CARD_PROOF_OF_IDENTITY}
        documentType={card.documentType}
        legalNameHeading={
          legalNameHeading()
        }
        name={card.nameOnDocument}
        nameChange={card.nameChange.name}
        document={card.documentName}
      >
        {index > 0 && (
          <div className="flex flex--grow-1 flex--align-end mt-10">
            <Button
              buttonClass="text--blue"
              btnStyle={BUTTON_STYLE_LINK__ICON}
              buttonPurpose={
                isApplicationName // Last item can only be changed, not removed.
                  ? card.nameChange.name
                  : card.documentName
              }
              label={
                isApplicationName // Last item can only be changed, not removed.
                  ? t(
                      "screens-Dashboard-changeLabel",
                      "Select a Different Document"
                    )
                  : t("screens-confirm-name-remove", "Remove")
              }
              icon={isApplicationName ? null : ICON_TRASH}
              iconPosition={BUTTON_ICON_LEFT}
              iconClass="mb-2"
              onClick={() => {
                // Last item can only be changed, not removed, as it tracks the name on the application.
                if (index === nameCards.length - 1) {
                  setCatDocId(index, null);
                } else {
                  setNameChangeToRemove({
                    ...card.nameChange,
                    index,
                  });
                }
              }}
            />
          </div>
        )}
      </NameConfirmationCard>
    </div>
  );
}

function NameCards({
  nameChanges,
  removeNameChange = () => null,
  certifyingDocuments,
  setCatDocId,
  selectedIdentityDocument,
  setLatestNameChangeIndex
}) {
  const { t } = useTranslation();
  const { applicationType } = useApplication();
  const [nameChangeToRemove, setNameChangeToRemove] = useState();

  const nameCards = useMemo(
    () =>
      (nameChanges || []).map((nameChange, index) => {
        return {
          id: nameChange.order,
          documentType: index === 0 
            ? applicationType === APPLICATION_TYPE.NCH
            ? NCH_NAME_CHANGE_DOCUMENT
            : IDENTITY_DOCUMENT 
            : NAME_CHANGE_DOCUMENT,
          nameOnDocument: formatName(nameChange),
          documentName:
            index === 0
              ? selectedIdentityDocument?.name
              : certifyingDocuments?.find(
                  ({ id }) =>
                    id ===
                      nameChanges[index === 0 ? nameChanges.length - 1 : index] // Use the last catDocId (which isn't rendered into a card) on the identity document.
                        ?.catDocId || ""
                )?.name,
          nameChange,
        };
      }),
    [certifyingDocuments, nameChanges, t]
  );

  const removeCard = () => {
    const { index, ...nameChange } = nameChangeToRemove;

    removeNameChange(nameChange);

    setLatestNameChangeIndex((previousIndex) => previousIndex - 1);
    setNameChangeToRemove(null);
  };

  return (
    <>
      <div className="w--100 flex flex--col bp-md:flex--row">
        {nameCards.length > 0 &&
          nameCards.map(
            (card, index) =>
              card.documentName && (
                <Card
                  key={card.id}
                  card={card}
                  index={index}
                  nameCards={nameCards}
                  setCatDocId={setCatDocId}
                  setNameChangeToRemove={setNameChangeToRemove}
                />
              )
          )}
      </div>
      {nameChangeToRemove && (
        <RemoveNameCardModal
          onClose={() => {
            setNameChangeToRemove(null);
          }}
          onChange={removeCard}
          nameChangeToRemove={nameChangeToRemove}
        />
      )}
    </>
  );
}

export default NameCards;
