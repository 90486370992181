import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  ALERT_ERROR,
  YesNoRadioGroup,
  Button,
  ButtonWrapper,
  useFocusedKey,
  RadioGroup,
  RadioItem,
  ALERT_INFO,
  ALERT_WARNING,
} from "@ca-dmv/core";
import { Trans, useTranslation } from "@ca-dmv-radv/translation";
import {
  RadvPageWrapper,
  NameConfirmationCard,
  CARD_RID_APPLICATION_NAME,
  ButtonSaveAndExit,
  ButtonBack,
  NameFields,
} from "@ca-dmv-radv/components";
import { useApplication, useIdentityDocument, useNameChanges } from "@ca-dmv-radv/data";
import useConfirmNameErrors from "../../identity/useConfirmNameErrors";
import useConfirmNameNavigation from "../../identity/useConfirmNameNavigation";
import { ICON_WARNING_RED } from "@ca-dmv/core/dist/js/icon";
import { useLocation } from "react-router-dom";
import useGenericConfirmNameNavigation from "./useGenericConfirmNameNavigation";
import { isApplicationNch } from "../../constants";
import { formatName } from "@ca-dmv-radv/utilities";

export default function GenericNameConfirmation({ title }) {
  const [showErrors, setShowErrors] = useState(false);
  const [isEdited, setIsEdited] = useState(null);
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  const { t } = useTranslation();
  const location = useLocation();

  const proofOfDocumentCategory = location.state?.proofOfDocumentCategory;
  const showNameOptions = JSON.parse(localStorage.getItem("showNameOptions")) || false;

  const { focusedElementKey } = useFocusedKey();
  const selectedDocument = location.state?.selectedDocument;

  const { application, applicationType } = useApplication();
  const {
    nameMatches,
    setNameMatches,
    selectedNameMatchesOption,
    setSelectedNameMatchesOption,
    nameOnDocument,
    clearNameChange,
    nameChanges,
    hasNameChangeDocs,
    setHasNameChangeDocs,
    noDocsOption,
    setNoDocsOption,
  } = useNameChanges();
  const { setFirstName, setMiddleName, setLastName, setSuffix } =
    useNameChanges();
  const previousHasNameChangeDocsOption = useRef(hasNameChangeDocs);

  const firstNameChange = nameChanges[0] || {
    firstName: "",
    lastName: "",
    suffix: "",
    middleName: "",
  };
  const { firstName, middleName, lastName, suffix } = firstNameChange;
  const errors = useConfirmNameErrors();
  const { requestNavigation, navigating } = useGenericConfirmNameNavigation({
    errors,
    setShowErrors,
    isEdited,
    proofOfDocumentCategory,
    selectedDocument,
  });
  const [nextButtonClick, setNextButtonClick] = useState(false);
  const isNch = isApplicationNch(applicationType);
  const nameOnFile = () => {
    const name = application?.application?.nameOnFile;
    if (name) {
      return `${name.firstName} ${name.lastName}`;
    } else {
      return "";
    }
  }

  // Clear all name fields if selectedNameMatchesOption is true.
  useEffect(() => {
    if (selectedNameMatchesOption === true) {
      clearNameChange(0);
    }
  }, [nameMatches]);

  // Clear noDocsOption when hasNameChangeDocs changes.
  useEffect(() => {
    if (hasNameChangeDocs === false && isNch) {
      setNoDocsOption(1);
    }
    else if (hasNameChangeDocs !== previousHasNameChangeDocsOption.current) {
      setNoDocsOption(null);
    }
  }, [hasNameChangeDocs]);

  // Clear noDocsOption when nameMatches changes.
  useEffect(() => {
    if (selectedNameMatchesOption === true) {
      setNoDocsOption(null);
      setHasNameChangeDocs(null);
    }
  }, [selectedNameMatchesOption]);

  // Set isEdited to true if anything is edited.
  useEffect(() => {
    setIsEdited((previousValue) => previousValue !== null);
  }, [firstName, middleName, lastName, suffix, nameMatches, selectedNameMatchesOption, noDocsOption]);

  // Call requestNavigation when Next button is clicked
  useEffect(() => {
    if (nextButtonClick) {
      requestNavigation();
      setNextButtonClick(false);
    }
  }, [nextButtonClick]);

  // Enable "Next" button only if all necessary fields are completed
  useEffect(() => {
    if (selectedNameMatchesOption || (hasNameChangeDocs !== null && lastName !== "")) {
      setIsNextDisabled(false);
    } else {
      setIsNextDisabled(true);
    }
  }, [selectedNameMatchesOption, hasNameChangeDocs, lastName]);

  return (
    <RadvPageWrapper
      pageHeading={t(
        `${applicationType}-screens-category${proofOfDocumentCategory.categoryId}-legend`, 
        `Proof of ${proofOfDocumentCategory.categoryName}`
      )}
      formProps={{
        isForm: true,
        showErrors,
        errors,
      }}
      pageTitle={title}
    >
    {!showNameOptions && 
      <div>
        <p className="text--blue-dark-2 mb-40 bp-md:mb-48 max-width--800">
          {t(
            "screens-returnToDashboard-backButton-warning",
            "Please avoid using the browser navigation buttons to make changes to your application."
          )}
        </p>
        <ButtonWrapper wrapperClass="flex--col-reverse mt-40 bp-md:mt-60">
        <Button
          label={t("screens-returnToDashboard-button-text", "Return to Dashboard")}
          buttonClass="bp-md:mr-24 mb-24 bp-md:mb-0"
          onClick={() => setNextButtonClick(true)}
          isLoading={navigating}
        />
      </ButtonWrapper>
      </div>
  }
  {showNameOptions && 
    <div className="max-width--600">
      <h3 className="text--h5 text--700 mb-8 bp-md:mb-16">
        {t("screens-name-change-certification-subHeading", "Name Change Certification")}
      </h3>
          {isNch ? 
            <div>
              <h5 className="text--sm">
                {selectedDocument?.allowTracingNames ? 
                  t(
                    "nch-screens-confirm-name-isthisYourNameSubLegend",
                    "If the name on your application does not match the name on your document, you will need to provide certification of the different name. If you have changed your name multiple times, you may be asked to provide certification for each name change."
                  ) :
                  t(
                    "nch-screens-confirm-name-isthisYourNameSubLegend-noTracing",
                    "The name on your Identity document must match the name on your application."
                  )
                }
              </h5>
              <YesNoRadioGroup
                containerClass="mb-0 max-width--600"
                name="nameMatches"
                legend={selectedDocument?.allowTracingNames ?
                  <Trans
                    i18nKey="nch-screens-confirm-name-isThisYourNameLegend"
                    defaults="Does your <span>{{document}}</span> indicate the name change from <span>{{nameOnFile}} (name on file)</span> to <span>{{fullName}} (name on application)</span>?"
                    values={{
                      fullName: nameOnDocument,
                      nameOnFile: nameOnFile(),
                      document:
                        selectedDocument?.name ||
                        t(
                          "screens-confirm-name-identityDocumentDescription",
                          "selected identity document"
                        ),
                    }}
                    components={{ span: <span className="text--blue" /> }}
                  /> :
                  <Trans
                    i18nKey="nch-screens-confirm-name-isThisYourNameLegend-noTracing"
                    defaults="Does your <span>{{document}}</span> indicate the name <span>{{fullName}} (name on application)</span>?"
                    values={{
                      fullName: nameOnDocument,
                      document:
                        selectedDocument?.name ||
                        t(
                          "screens-confirm-name-identityDocumentDescription",
                          "selected identity document"
                        ),
                    }}
                    components={{ span: <span className="text--blue" /> }}
                  />
                }
                yesLabel={t("shared.yesLabel", "Yes")}
                noLabel={t("shared.noLabel", "No")}
                onChange={setSelectedNameMatchesOption}
                value={selectedNameMatchesOption}
                focusedElementKey={focusedElementKey}
                showErrors={showErrors}
                errorKey="nameMatches"
                translatedErrors={errors}
                yesValue
                noValue={false}
              />
            </div>
            :
            <div>    
              <YesNoRadioGroup
                containerClass="mb-0 max-width--600"
                name="nameMatches"
                components={{ span: <span className="text--blue" /> }}
                subLegend={t(
                  "nch-screens-confirm-name-isthisYourNameSubLegend",
                  "If the name on your application does not match the name on your document, you will need to provide certification of the different name. If you have changed your name multiple times, you may be asked to provide certification for each name change."
                )}
                yesLabel={t("shared.yesLabel", "Yes")}
                noLabel={t("shared.noLabel", "No")}
                onChange={setSelectedNameMatchesOption}
                value={selectedNameMatchesOption}
                focusedElementKey={focusedElementKey}
                showErrors={showErrors}
                errorKey="nameMatches"
                translatedErrors={errors}
                yesValue
                noValue={false}
              />
            </div>
          }
      {(selectedNameMatchesOption === false && selectedDocument?.allowTracingNames) && (
        <>
          <NameFields
            namePrompt={
              <Trans
                i18nKey="nch-screens-confirm-name-whatNameIsOn"
                defaults="What other name appears on your document which connects to <span>{{nameOnFile}} (name on file)</span>?"
                values={{
                  nameOnFile: nameOnFile()
                }}
                components={{ span: <span className="text--blue" /> }}
              />
            }
            firstName={firstName}
            middleName={middleName}
            lastName={lastName}
            suffix={suffix}
            onChangeFirstName={(newFirstName) => {
              setFirstName(0, newFirstName);
            }}
            onChangeMiddleName={(newMiddleName) => {
              setMiddleName(0, newMiddleName);
            }}
            onChangeLastName={(newLastName) => {
              setLastName(0, newLastName);
            }}
            onChangeSuffix={(newSuffix) => {
              setSuffix(0, newSuffix);
            }}
            errors={errors}
            showErrors={showErrors}
          focusedElementKey={focusedElementKey}
          />
          <YesNoRadioGroup
            containerClass="mt-40 bp-md:mt-48 mb-0 w--100 max-width--600 mb-24"
            name="doYouHaveDocuments"
            legend={
              <Trans
                i18nKey="nch-screens-confirm-name-doYouHaveDocumentsToUploadLegend"
                defaults="Do you have a document that connects the name entered above to <span>{{nameOnFile}} (name on file)</span>?"
                values={{
                  nameOnFile: nameOnFile()
                }}
                components={{ span: <span className="text--blue" /> }}
              />
            }
            yesLabel={t("shared.yesLabel", "Yes")}
            noLabel={t(
              "screens-confirm-name-doYouHaveDocumentsToUpload-NoLabel",
              "No, I do not have access to one or more of my documents."
            )}
            onChange={setHasNameChangeDocs}
            value={hasNameChangeDocs}
            focusedElementKey={focusedElementKey}
            showErrors={showErrors}
            errorKey="hasNameChangeDocs"
            translatedErrors={errors}
            yesValue
            noValue={false}
          />
        </>
      )}
      {(selectedNameMatchesOption == false && !selectedDocument?.allowTracingNames && isNch) &&
        <div className="pt-20">
          <Alert
            alertStyle={ALERT_WARNING}
            hasBorder={false}
          >
            <p className="text--primary text--h3 text--sm pr-0 pl-0">{t(`${applicationType}-screens-category${proofOfDocumentCategory.categoryId}-NoTracingAllowed`, "The name on this document must match the name on the application for this document type. Select a new document.")}</p>
          </Alert>
        </div>
      }
       {(hasNameChangeDocs === false && selectedNameMatchesOption === false && !isNch) && (
        <RadioGroup
          containerClass="w--100 max-width--600 mt-40 bp-md:mt-48 mb-0 pb-0"
          legend={t(
            "screens-confirm-name-documentsToUploadLegend",
            "Since you are unable to provide name change certification documents, select from one of the options."
          )}
          error={errors && showErrors && errors.noDocsOption}
        >
          <RadioItem
            label={t(
              "screens-confirm-name-documentsToUpload-Option1Label",
              "I will obtain certified or original copies of my name change document(s) and upload them at a later time."
            )}
            containerClass="mb-24"
            radioStyle="bordered"
            radioClass="w--100 bp-md:max-width--600 mb-0 pb-0"
            name="noDocsOption"
            checked={noDocsOption === 1}
            onChange={() => setNoDocsOption(1)}
            hideAsterisk
            hasFocus={focusedElementKey && focusedElementKey === "noDocsOption"}
          />
          <RadioItem
            label={t(
              "screens-confirm-name-documentsToUpload-Option2Label",
              "I would like to use the name on my identity document to obtain a REAL ID driver’s license or identification card."
            )}
            radioStyle="bordered"
            radioClass="w--100 bp-md:max-width--600 mb-0 pb-0"
            name="noDocsOption"
            checked={noDocsOption === 2}
            onChange={() => setNoDocsOption(2)}
            hideAsterisk
          />
        </RadioGroup>
      )}
      {(hasNameChangeDocs === false && selectedNameMatchesOption === false && isNch) && (
        <Alert
          alertStyle={ALERT_INFO} 
          className=""
        >
          <p className="pr-0 pl-0">{t(`${applicationType}-screens-category${proofOfDocumentCategory.categoryId}-noDocsMessage`, "You will need to obtain your certified or original copies of your name change document(s) and upload them.")}</p>
        </Alert>
      )}
      <ButtonWrapper wrapperClass="flex--col-reverse mt-40 bp-md:mt-60">
        <ButtonBack buttonClass="bp-md:mr-24" />
        <ButtonSaveAndExit buttonClass="bp-md:mr-24 mb-24 bp-md:mb-0" />
        <Button
          label={t("shared.nextLabel", "Next")}
          buttonClass="bp-md:mr-24 mb-24 bp-md:mb-0"
          onClick={() => setNextButtonClick(true)}
          isLoading={navigating}
          disabled={isNextDisabled}
        />
      </ButtonWrapper>
    </div>}
    </RadvPageWrapper>
  );
}