import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, ALERT_BLUE, ALERT_WARNING, Button, ButtonWrapper, BUTTON_STYLE_SECONDARY, STATUS_IN_PROGRESS, STATUS_ERROR, STATUS_VERIFYING, STATUS_PREAPPROVED, STATUS_READY, STATUS_REVIEW, STATUS_SUBMITTING } from "@ca-dmv/core";
import { RadvPageWrapper, ButtonSaveAndExit } from "@ca-dmv-radv/components";
import { useTranslation } from "@ca-dmv-radv/translation";
import { isMobile } from "react-device-detect";
import {
  useApplication,
  useDocumentUploads,
  getApplicationStatus,
  useGoogleAnalytics,
  useThrowFetchError,
  getCategorizedDocsByBusinessProgram,
  getDocumentSectionStatus,
  APPLICATION_TYPE,
  DOCUMENT_CATEGORY_NCH_NAME_TRACING
} from "@ca-dmv-radv/data";
import { usePDFWorker } from "@ca-dmv-radv/files";
import { Modal } from "@ca-dmv/modal";
import useDashboardStatus, {
  DASHBOARD_STATUS_DONE,
  DASHBOARD_STATUS_READY_TO_SUBMIT,
  DASHBOARD_STATUS_UNDER_REVIEW,
  DASHBOARD_STATUS_VERIFYING,
  DASHBOARD_STATUS_SUBMITTING,
} from "../dashboard/helpers/useDashboardStatus";
import { autoSubmitOptIn } from "../dashboard/helpers/constants";
import HeaderText from "../dashboard/HeaderText";
import useFocusableElement from "../dashboard/helpers/useFocusableElement";
import ReadyToSubmit from "../dashboard/ReadyToSubmit";
import addNexisScript from "../dashboard/helpers/addNexisScript";
import Barcodes from "../dashboard/Barcodes";
import { getDeviceWarningMessageFlag, getReadyToSubmit } from "../../../data/src/api/index";
import ProofOfDocument from "../dashboard/ProofOfDocument";
import { categoryIds, isApplicationNch } from "../constants";

const pageHeading = ({ dashboardStatus, t }) => {
  if (dashboardStatus === DASHBOARD_STATUS_DONE) {
    return t("screens-confirmation-heading", "Good News!");
  }

  return t("screens-Dashboard-pageHeading", "Document Submission");
};

const updateDashboardStatus = (allDocs, application) => {

  if (allDocs.length === 0) return null;

  const dashboardStatuses = {
    [STATUS_IN_PROGRESS]: null,
    [STATUS_ERROR]: null,
    [STATUS_VERIFYING]: DASHBOARD_STATUS_VERIFYING,
    [STATUS_PREAPPROVED]: DASHBOARD_STATUS_DONE,
    [STATUS_REVIEW]: DASHBOARD_STATUS_UNDER_REVIEW,
    [STATUS_READY]: DASHBOARD_STATUS_READY_TO_SUBMIT,
    [STATUS_SUBMITTING]: DASHBOARD_STATUS_SUBMITTING
  };

  // Dashboard status is determined by which document section has the smallest weight value
  let status = getDocumentSectionStatus(allDocs)

  // User would have already submitted application, so dashboard status should be set accordingly
  if (application.status.DOCS_VERIFIED_DT && (application.status.FINISHED || 
   (application?.skipDMV && application?.paymentFinished))) {
    status = STATUS_PREAPPROVED;
  }
  
  return dashboardStatuses[status];
};

let sessionId;

const objectIsEmpty = (obj) => {
  return obj === null || Object.values(obj).every(e => e === null);
}

export default function DynamicAppsDashboard({ applicationType }) {
  const {
    submitting,
    setSubmitting,
    submitApplication,
    fetchApplication,
    application,
    setValidationComplete,
    validationComplete,
    submitAutoSubmit,
  } = useApplication();
  const {
    documentUploads,
    useDocumentUploadPing,
    setShouldPingDocumentUploads,
  } = useDocumentUploads();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setAnalyticsAction } = useGoogleAnalytics();
  const throwFetchError = useThrowFetchError();
  const [showAutoSubmitModal, setShowAutoSubmitModal] = useState(false);
  const [shouldAutoSubmit, setShouldAutoSubmit] = useState(false);
  const [categoryAndDocs, setCategoryAndDocs] = useState();
  const [allCategoryDocs, setAllCategoryDocs] = useState([]);
  const dashboardStatus = updateDashboardStatus(allCategoryDocs, application);
  const [previousDashboardStatus, setPreviousDashboardStatus] = useState(dashboardStatus);
  const [showApplicationErrorModal, setShowApplicationErrorModal] = useState(false);

  const submitLabel = applicationType === APPLICATION_TYPE.NCH && application.skipDMV
    ? t("nch-screens-Dashboard-contactless-submitText", "Submit")
    : t("shared.submitLabel", "Submit and Book DMV Appointment");

  useDocumentUploadPing();
  usePDFWorker();

  sessionId = application?.lnSessionId;
  addNexisScript(sessionId);

  const { statusAlertRef, proofOfIdentityRef, proofOfResidencyRef } =
    useFocusableElement();

  const [deviceWarnMsg, setDeviceWarnMsg] = useState({});
  useEffect(() => {
    (async () => {
      try{
      const devicWarningMsgResponse = await getDeviceWarningMessageFlag();
      if (devicWarningMsgResponse.success) {
        setDeviceWarnMsg(devicWarningMsgResponse.data);
      }
    }catch(e){
      switch (e.response?.status) {
        case 503: // Under Maintenance
          throw new MaintenanceEnabled(e);
        case 404: // Not Found
          throw new ApplicationNotFound(e);
        case 401: // Unauthorized
          throw new SessionExpired(e);
        default:
          throw e;
      }
    }
    })();
  }, []);

  // Dashboard-status-related side effects.
  useEffect(() => {
    switch (dashboardStatus) {
      case DASHBOARD_STATUS_VERIFYING:
        if (dashboardStatus !== previousDashboardStatus) {
          setAnalyticsAction("Requirements Completed");
        }

        setShouldPingDocumentUploads(true);
        break;
      case DASHBOARD_STATUS_UNDER_REVIEW:
        setShouldPingDocumentUploads(true);
        break;
      case DASHBOARD_STATUS_DONE:
      case DASHBOARD_STATUS_READY_TO_SUBMIT:
        setShouldPingDocumentUploads(false);
        break;
      default:
        setShouldPingDocumentUploads(true);
        break;
    }
  }, [dashboardStatus, previousDashboardStatus]);

  useEffect(() => {
    setPreviousDashboardStatus(dashboardStatus);

    if (dashboardStatus === DASHBOARD_STATUS_DONE) {
      navigate("/final-confirmation");
      return;
    }
  }, [dashboardStatus]);

  const shouldShowSaveAndExit = ![
    DASHBOARD_STATUS_VERIFYING,
    DASHBOARD_STATUS_READY_TO_SUBMIT,
    DASHBOARD_STATUS_SUBMITTING,
    DASHBOARD_STATUS_UNDER_REVIEW,
    DASHBOARD_STATUS_DONE,
  ].includes(dashboardStatus);

  const shouldShowSaveAndExitToUnderReview = [
    DASHBOARD_STATUS_VERIFYING,
    DASHBOARD_STATUS_UNDER_REVIEW,
  ].includes(dashboardStatus);

  const shouldShowSubmit = [
    DASHBOARD_STATUS_READY_TO_SUBMIT,
    DASHBOARD_STATUS_SUBMITTING,
  ].includes(dashboardStatus);

  useEffect(() => {
    if (!shouldShowSubmit) {
      return;
    }

    (async () => {
      let success;
      let message;
      let statusResponse;

      try {
        if (isApplicationNch(applicationType)) {
          ({
            success,
            message,
            data: statusResponse,
          } = await getReadyToSubmit(applicationType));
        } else {
          ({
            success,
            message,
            data: statusResponse,
          } = await getApplicationStatus(applicationType));
        }

        setValidationComplete(statusResponse && success);
      } catch (error) {
        throwFetchError({
          error,
          message,
        });
      }
    })();
  }, [shouldShowSubmit]);

  const submitApplicationHandler = async () => {
    if (!submitting) {
      setShouldPingDocumentUploads(false);
      setSubmitting(true);
      setAnalyticsAction("Submit Application");
      const success = await submitApplication();
      if (success) {
        await fetchApplication();
        if (applicationType === APPLICATION_TYPE.NCH) {
          navigate("/final-confirmation");
        }
        window.scrollTo(0, 0);
      }
      setSubmitting(false);
    }
  };

  const onSubmit = async () => {
    setSubmitting(true);
    await submitApplicationHandler();
  };

  useEffect(() => {
    (async () => {
      try{
      const categorizedDocsResponse = await getCategorizedDocsByBusinessProgram(applicationType);
      if (categorizedDocsResponse.success) {
        setCategoryAndDocs(categorizedDocsResponse.data);
      }
    }catch(e){
      switch (e.response?.status) {
        case 503: // Under Maintenance
          throw new MaintenanceEnabled(e);
        case 404: // Not Found
          throw new ApplicationNotFound(e);
        case 401: // Unauthorized
          throw new SessionExpired(e);
        default:
          throw e;
      }
    }
    })();
  }, []);

  useEffect(() => {
    if (objectIsEmpty(application.application.nameOnFile)) {
      setShowApplicationErrorModal(true);
    } else {
      setShowApplicationErrorModal(false);
    }
  }, []);

  useEffect(() => {
    let docs = [];
    if (categoryAndDocs) {
      categoryAndDocs.map((category) => {
        if (documentUploads[category.categoryId]) {
          let categoryDocs = [...documentUploads[category.categoryId]];
          if (applicationType === APPLICATION_TYPE.NCH && category.categoryId === categoryIds.NCH_NAME_CHANGE && documentUploads[DOCUMENT_CATEGORY_NCH_NAME_TRACING]) {
            categoryDocs = [...categoryDocs, ...documentUploads[DOCUMENT_CATEGORY_NCH_NAME_TRACING]];
          }
          docs = [...docs, ...categoryDocs];
        }
      });
      setAllCategoryDocs(docs);
    }
  }, [categoryAndDocs, documentUploads]);

  // Show warning when user clicks browser back or forward button while on dashboard
  useEffect(() => {
    const handlePopState = (event) => {
      // Sample message, will replace with approved language
      const message = t("screens-backButton-warning", "You may experience issues with your application if you use browser buttons to navigate from this page. Click 'Cancel' to stay on this dashboard."); 
      localStorage.setItem("showNameOptions", false);
      if (!window.confirm(message)) {
        event.preventDefault();
        window.history.back();
      }
    };

    window.addEventListener('popstate', handlePopState);
    
    return () => {
      window.removeEventListener('popstate', handlePopState);
    }
  }, []);

  return (
    <RadvPageWrapper
      pageHeading={pageHeading({ dashboardStatus, t })}
      formProps={{
        isForm: false,
      }}
    >
      <HeaderText dashboardStatus={dashboardStatus} />
      {showApplicationErrorModal && (
        <Modal
          modalTitle={t("nch-app-error-unableToRetrieve-header", "Service Temporarily Unavailable")}
          titleClass="text--h3 text--primary text--blue-dark-2 text--md text--lh-1"
        >
          <div>
            <p className="mb-24">
              {t(
                "nch-app-error-unableToRetrieve-subheader",
                "We have identified a technical issue and are working fast to resolve it. Try again later."
              )}
            </p>
            <Button
              buttonClass="bp-md:mr-24 mb-24 bp-md:mb-0"
              label={t("screens-Dashboard-saveAndExit", "Save and Exit")}
              onClick={() => {
                setAnalyticsAction("Save and Exit Under Review");
                navigate("/saved");
              }}
            />          
          </div>
        </Modal>
      )}
      {showAutoSubmitModal && (
        <Modal
          modalTitle={t("document-submission-header", "Document Submission")}
          titleClass="text--blue-dark-2 text--md bp-md:text--xlg bp-lg:text--xxlg text--uppercase text--italics text--italics text--700 text--lh-1"
        >

          <p className="mb-24">
            {t(
              "document-submission-subheader",
              "To save time, would you like DMV to automatically submit your uploaded documents after verification?"
            )}
          </p>
          <div>
            <div
              style={{ display: "flex", flexDirection: "column" }}
              className="bp-md:w--45 w--100"
            >
              <Button
                label={t("auto-submit-yes", "Yes, auto submit")}
                onClick={() => setAutoSubmit(autoSubmitOptIn.OPTED_IN)}
                buttonClass="w--100 mb-12"
              />
              <Button
                label={t("auto-submit-no", "No, I will submit later")}
                btnStyle={BUTTON_STYLE_SECONDARY}
                onClick={() => setAutoSubmit(autoSubmitOptIn.OPTED_OUT)}
                buttonClass="w--100"
              />
            </div>
          </div>
        </Modal>
      )}
      {
        (() => {
          if (isMobile && dashboardStatus !== DASHBOARD_STATUS_DONE && typeof deviceWarnMsg !== 'undefined' && deviceWarnMsg?.messageCode && deviceWarnMsg?.defaultMessage) {
            return <Alert
              alertStyle={ALERT_WARNING}
              ariaLive="on"
              containerClass="mt-24 mb-24 bp-md:max-width--500 bp-lg:max-width--900"
              hasBorder={false}
            >
              <p className="mb-0">
                {t(
                  deviceWarnMsg.messageCode,
                  deviceWarnMsg.defaultMessage
                )}
              </p>
            </Alert>
          }
        })()
      }
    {categoryAndDocs && categoryAndDocs.map((categoryAndDoc) => (
      <div ref={proofOfIdentityRef} id= {"proof-of-"+categoryAndDoc.categoryId} key={categoryAndDoc.categoryId}>   
        <ProofOfDocument 
          selectListOfDocs={categoryAndDoc.documents} 
          proofOfDocumentCategory={categoryAndDoc} 
          applicationType={applicationType} 
          application={application}
        />
      </div>
    ))}
      {((application.autoSubmit === autoSubmitOptIn.OPTED_IN) && shouldShowSaveAndExitToUnderReview) && (
        <Alert
          alertStyle={ALERT_BLUE}
          ariaLive="on"
          containerClass="mt-24 mb-24 bp-md:max-width--500 bp-lg:max-width--900"
          hasBorder={false}
        >
          <p className="mb-0">
            {t(
              "screens-Dashboard-autoSubmit-indication-message",
              "Your documents will be automatically submitted after verification. You will receive an email with the confirmation code once your documents have been pre-approved."
            )}
          </p>
        </Alert>
      )}
      {dashboardStatus === DASHBOARD_STATUS_READY_TO_SUBMIT && (
        <div ref={statusAlertRef}>
          <ReadyToSubmit skipDmv={application?.skipDMV}/>
        </div>
      )}

      <ButtonWrapper wrapperClass="flex--col-reverse mt-40 bp-md:mt-60">
        {!showAutoSubmitModal && shouldShowSaveAndExit && (
          <ButtonSaveAndExit buttonClass="bp-md:mr-24 mb-24 bp-md:mb-0" />
        )}
        {!showAutoSubmitModal && shouldShowSaveAndExitToUnderReview && (
          <Button
            buttonClass="bp-md:mr-24 mb-24 bp-md:mb-0"
            label={t("screens-Dashboard-saveAndExit", "Save and Exit")}
            onClick={() => {
              setAnalyticsAction("Save and Exit Under Review");
              navigate("/saved");
            }}
          />
        )}
        {(shouldShowSubmit && validationComplete) &&
          (!application ||
            application.autoSubmit !== autoSubmitOptIn.OPTED_IN) && (
            <Button
              label={submitLabel}
              isLoading={
                submitting || dashboardStatus === DASHBOARD_STATUS_SUBMITTING
              }
              disabled={
                submitting || dashboardStatus === DASHBOARD_STATUS_SUBMITTING
              }
              onClick={onSubmit}
            />
          )}
      </ButtonWrapper>
      {dashboardStatus === DASHBOARD_STATUS_DONE && (
        <Barcodes barcodeType={417} />
      )}
    </RadvPageWrapper>
  );
}

export { sessionId };