import React from "react";
import {
  Separator,
  Button,
  BUTTON_STYLE_LINK__ICON_UNDERLINE,
} from "@ca-dmv/core";
import { useNavigate } from "react-router-dom";
import { useApplication, useDocumentUploads, useGoogleAnalytics, useNameChanges } from "@ca-dmv-radv/data";
import { Trans, useTranslation } from "@ca-dmv-radv/translation";
import Document from "../Document";
import getDocumentUploadStatusFlag from "../helpers/getDocumentUploadStatusFlag";
import { Alert, ALERT_INFO } from "@ca-dmv/core/dist/js/alert";
import { useEffect } from "react";

export default function NameChangeUploads({ nameChangeCatDocData, proofOfDocumentCategory }) {
  const navigate = useNavigate();
  const { nameChangeDocumentUploads } = useDocumentUploads();
  const { documentType } = useNameChanges();
  const { application, applicationType } = useApplication();
  const { setAnalyticsAction } = useGoogleAnalytics();
  const { t } = useTranslation();
  let previousName = `${application.application?.nameOnFile?.firstName} ${application.application?.nameOnFile?.lastName}`;

  const getInfoMessage = (doc) => {
    return t(
      `${applicationType}-screens-category${doc.categoryId}-infoMessage`, 
      `${doc.categoryName} informational message.`
    ) || "";
  }

  const getFromName = () => {
    return previousName;
  }

  const getToName = (doc, nameChangeLength) => {
    if (doc.order !== nameChangeLength) {
      previousName = doc.nameChange;
      return doc.nameChange;
    } else {
      return `${application.documents?.nameChange?.documentName.firstName || ""} ${application.documents?.nameChange?.documentName.lastName || ""}`;
    }
  }

  useEffect(() => {
    // Sort name tracing docs by order of name changes
    if (nameChangeDocumentUploads) {
      nameChangeDocumentUploads.sort((a, b) => a.order > b.order);
    }
  }, [nameChangeDocumentUploads]);

  return (
    <div className="bp-md:ml-30">
      <Separator
        heading={t(
          "screens-ProofOfIdentity-nameChangeDocumentLabel",
          "Name Change Certification Document"
        )}
        containerClass="mt-40 mb-8"
      />
      {nameChangeDocumentUploads?.length > 0 &&
        nameChangeDocumentUploads.map((doc, index) => (
          <div key={index}>
            {getInfoMessage(doc) && 
            <Alert
              alertStyle={ALERT_INFO}
              ariaLive="on"
              containerClass="bp-md:max-width--900 bp-lg:max-width--900"
            >
              <Trans
                i18nKey={`nch-screens-category${doc.categoryId}-infoMessage`}
                defaults={getInfoMessage(doc)}
                components={{
                  p: <span className="show mb-0 text--sm" />,
                  b: <b />,
                }}
                values={{
                  previousName: getFromName(doc),
                  nextName: getToName(doc, nameChangeDocumentUploads.length)
                }}
              />
            </Alert>}
            <Document
              containerClass={
                index < nameChangeDocumentUploads.length - 1
                  ? "mb-30 js-doc-wrap"
                  : "mb-0 js-doc-wrap"
              }
              key={`name-change-document-${doc.id}`}
              documentUpload={doc}
              isCancelable
              onSelectDifferentDocument={() => {
                localStorage.setItem("showNameOptions", true);
                navigate("/name-confirmation", {
                  state: {
                    proofOfDocumentCategory: proofOfDocumentCategory,
                    selectedDocument: nameChangeCatDocData,
                  },
                });
              }}
              selectDifferentDocumentLabel={t(
                "screens-Dashboard-changeLabel",
                "Select a Different Document"
              )}
              status={getDocumentUploadStatusFlag(doc)}
            />
          </div>
        ))}
      {!nameChangeDocumentUploads?.length && (
        <div className="flex flex--justify-center bp-md:flex--justify-start">
          <Button
            btnStyle={BUTTON_STYLE_LINK__ICON_UNDERLINE}
            label={t(
              "screens-ProofOfIdentity-selectDocumentButtonLabel",
              "Select Certifying Document"
            )}
            onClick={() => {
              setAnalyticsAction("Selecting Name Change Documents");
              navigate("/name-confirmation", {
                state: {
                  proofOfDocumentCategory: proofOfDocumentCategory,
                  selectedDocument: nameChangeCatDocData,
                },
              });
            }}
          />
        </div>
      )}
    </div>
  );
}
