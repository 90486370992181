import { isBirthCertificate, isPassPort, isPassportCard } from '@ca-dmv-radv/screens/src/dashboard/helpers/constants';

import { APPLICATION_TYPE, DEFAULT_MAX_UPLOAD_SIZE } from '../constants';
import ApplicationNotFound from '../errors/ApplicationNotFound';
import MaintenanceEnabled from '../errors/MaintenanceEnabled';
import SessionExpired from '../errors/SessionExpired';
import getData from './get-data';
import HTTP from './http';
import postData from './post-data';
import STORAGE from './storage';
import { osName } from 'react-device-detect';

let appLanguage = "en";
/**
 * Set the application language to be used on all requests
 *
 * @param {string} language
 */
export function setLanguage(language) {
  appLanguage = language;
}

/**
 * Intercept all requests and set the application language
 *
 * @param {object} config
 * @returns {object}
 */
function setConfigLanguage(config) {
  if (appLanguage === "en") {
    return config;
  }
  return {
    ...config,
    headers: {
      ...config.headers,
      "accept-language": `${appLanguage}, en`,
    },
  };
}

HTTP.interceptors.request.use(setConfigLanguage);
STORAGE.interceptors.request.use(setConfigLanguage);

/**
 * Get application data by UUID
 *
 * @returns {Promise<object>}
 */
export async function getApplicationData(applicationType) {
  let response;
  try {
    const url =
      applicationType !== APPLICATION_TYPE.DV
        ? `/${applicationType}/application`
        : "/application";
    response = await HTTP.get(url);
    if (response.data.success === true) {
      return {
        uuid: response.data.data.uuid,
        lnSessionId: response.data.data.lnSessionId,
        ...response.data.data.data,
        success: true,
      };
    }
    throw new Error(response.data.message);
  } catch (e) {
    switch (e.response?.status) {
      case 503: // Under Maintenance
        throw new MaintenanceEnabled(e);
      case 404: // Not Found
        throw new ApplicationNotFound(e);
      case 401: // Unauthorized
        throw new SessionExpired(e);
      default:
        throw e;
    }
  }
}

/**
 * Update applicant email
 *
 * @param {string} email
 * @returns {(Object|Error)} response data or error
 */
export function updateApplicantEmail(email) {
  return postData("/application/email", email, {
    "Content-Type": "text/plain",
  });
}

/**
 * Update applicant email
 *
 * @param {boolean} autoSubmit
 * @returns {(Object|Error)} response data or error
 */
export function updateAutoSubmit(autoSubmit) {
  return postData(
    "/application/autoSubmitOptIn",
    { autoSubmit },
    { "Content-Type": "application/json" }
  );
}

/** Catalogs */

/**
 * Get states list
 *
 * @returns {Function}
 */
export function getStates(applicationType) {
  const url = `${applicationType}/catalogs/states`;
  return getData(url);
}

/**
 * Get counties list
 *
 * @returns {Function}
 */
export function getCounties(applicationType) {
  const url = `${applicationType}/catalogs/county`;
  return getData(url);
}

/**
 * Get documents list by category
 *
 * @param {int} categoryId
 * @returns {Function}
 */
export function getDocumentsByCategory(categoryId, applicationType) {
  const url =
    applicationType !== APPLICATION_TYPE.DV
      ? `/${applicationType}/categorizedDocuments?categoryId=${categoryId}`
      : `/catalogs/categorizedDocuments?categoryId=${categoryId}`;
  return getData(url);
}

/**
 * Get documents by categorizedDocumentId
 *
 * @param {int} categorizedDocumentId
 * @returns {Function}
 */
export function getCategorizedDocumentById(categorizedDocumentId) {
  return getData(`catalogs/categorizedDocuments/${categorizedDocumentId}`);
}

export function getMDLCategorizedDocumentById(categorizedDocumentId) {
  return getData(`mdl/categorizedDocuments/${categorizedDocumentId}`);
}

/**
 * Get Office data by Id
 *
 * @param {int} officeId
 * @returns {Function}
 */
export function getOfficeById(officeId) {
  return getData(`catalogs/office/${officeId}`);
}

/** Identity */

/**
 * Select identity document endpoint
 *
 * @param {object} dto
 * @returns {Function}
 */
export function updateIdentityDocument(dto, applicationType) {
  const baseUrl = `/application/identity/documentSelection`;
  const url =
    applicationType !== APPLICATION_TYPE.DV ? `/${applicationType}${baseUrl}` : `${baseUrl}`;
  return postData(baseUrl, dto);
}

/**
 * Select document endpoint
 *
 * @param {object} dto
 * @returns {Function}
 */
export function updateDocumentByCategory(dto, applicationType) {
  return postData(`/${applicationType}/documentSelection`, dto);
  // return Promise.resolve({success:true});
}

/**
 * Confirm your name endpoint
 *
 * @param {object} dto
 * @returns {Function}
 */
export function updateConfirmName(dto, applicationType) {
  const url = applicationType !== APPLICATION_TYPE.DV 
    ? `/${applicationType}/confirmName` 
    : "/application/identity/confirmName";
  return postData(url, dto);
}
/**
 * Gets the name changes
 *
 * @returns {Function}
 */
export function getNameChanges(applicationType) {
  const url = applicationType !== APPLICATION_TYPE.DV 
    ? `/${applicationType}/nameChanges`
    : "/application/identity/nameChanges";
  return getData(url);
}

/**
 * Gets the mdl name changes
 *
 * @returns {Function}
 */
/**
 * Update name changes
 *
 * @param {object} dto
 * @returns {Function}
 */
export function updateNameChanges(dto, applicationType) {
  const url = applicationType !== APPLICATION_TYPE.DV
    ? `/${applicationType}/nameChanges`
    : "/application/identity/nameChanges"
  return postData(url, dto);
}

/**
 * Update name changes document
 *
 * @param {object} dto
 * @returns {Function}
 */
export function postNameChangesDocument(dto, applicationType) {
  const url = applicationType !== APPLICATION_TYPE.DV
    ? `/${applicationType}/nameChangesDocument`
    : "/application/identity/nameChangesDocument";
  return postData(url, dto);
}

/**
 * Update Residency
 *
 * @param {object} dto
 * @param {int} documentIndex
 * @returns {Function}
 */
export function updateResidencyDocuments(dto, documentIndex) {
  return postData(
    `/application/residency/documentSelection/${documentIndex}`,
    dto
  );
}

/**
 * Update confirmation res doc name
 *
 * @param {object} dto
 * @returns {Function}
 */
export function updateConfirmResDocName(dto) {
  return postData("/application/residency/documentDetails", dto);
}

/**
 * Update connection document
 *
 * @param {int} categoryId
 * @param {string} document
 * @returns {Function}
 */
export function updateConnectionDoc(categoryId, document) {
  return postData(
    `/application/residency/connecting/${categoryId}/${document}`
  );
}

/**
 * Get appointment link
 *
 * @returns {Function}
 */
export function getAppointmentLink() {
  return getData("/application/showAppointmentLink");
}

export function getDocTypes(type) {
  return getData(`/${type}/catalogs/categorizedDocs`);
}

export function getCategorizedDocsByBusinessProgram(type) {
  return getData(`/${type}/catalogs/business-programs/categorized-docs`);
}

/**
 * Get bar code 39
 *
 * @param {string} confirmationCode
 * @returns {Function}
 */
export function getBarcode39(confirmationCode) {
  return getData(
    `/application/barcode/39?confirmationCode=${confirmationCode}`
  );
}

/**
 * Get barcode PDF 417
 * @param {string} confirmationCode
 * @param {string} lastName
 * @returns {Function}
 */
export function getBarcodePdf417(confirmationCode, lastName) {
  return getData(
    `/application/barcode/417?confirmationCode=${confirmationCode}&lastName=${lastName}`
  );
}

/**
 * Upload Management - DocUpload List
 *
 * @returns {Function}
 */
export function getApplicationDocUploads(applicationType) {
  const url = applicationType !== APPLICATION_TYPE.DV 
  ? `/${applicationType}/application/documentUploads`
  : "/application/documentUploads";
  return getData(url);
}

/**
 * Get the application status
 *
 * @returns {Function}
 */
export function getApplicationStatus() {
  return getData("/application/status");
}

/**
 * Get ready to submit status
 * 
 */
export function getReadyToSubmit(applicationType) {
  return getData(`/${applicationType}/ready-to-submit`);
}

/**
 * Send SMS
 *
 * @param {string} phoneNumber
 * @returns {Function}
 */
export function sendSms(phoneNumber, documentId, applicationType) {
  const url = applicationType !== APPLICATION_TYPE.DV
  ? `/${applicationType}/${phoneNumber}`
  : `/sms/${phoneNumber}`;
  return postData(url, documentId);
}

/**
 * Submit to FODI
 *
 * @returns {Function}
 */
export function submitToFODI() {
  return postData("/application/fodi");
}

/**
 * Invalidates an idle session
 *
 * @returns {Function}
 */
export function invalidateSession() {
  return postData("/application/idleSession");
}

/**
 * Process a mobile session
 *
 * @param {string} token
 * @returns {Function}
 */
export function mobileSessionDynamic(token, applicationType) {
  if ([APPLICATION_TYPE.DV, APPLICATION_TYPE.NCH].includes(applicationType)) {
    const appType = applicationType === APPLICATION_TYPE.DV
      ? "rid"
      : applicationType;
    window.location = `${process.env.REACT_APP_API_URL}/${appType}/mobileSession/${token}`;
  } else {
    return postData(`/${applicationType}/mobileSession`, token, {
      "Content-Type": "text/plain",
    });
  }
}

/**
 * Process a mobile session
 *
 * @param {string} token
 * @returns {Function}
 */
export function mobileSessionDv(token) {
  return postData("/sms/mobileSession", token, {
    "Content-Type": "application/json; charset=UTF-8",
  });
}

/** Emails */

/**
 * Send email session save
 *
 * @param {string} email
 * @returns {Function}
 */
export function sendEmailSessionSave(email) {
  return postData("email/sessionSaved", { email });
}

/**
 * Send new session saved email (app pending)
 *
 * @param {string} email
 * @returns {Function}
 */
export function sendEmailApplicationPending() {
  return postData("email/applicationPending");
}

/**
 * Send under review email
 * @param {string} email
 * @returns {Function}
 */
export function sendEmailUnderReview(email) {
  return postData("email/underReview", { email });
}

/**
 * Submit the final email confirmation if an appointment is made
 * @param {object} dto
 * @returns {Function}
 */
export function sendEmailAppointmentConfirmation(dto) {
  return postData("email/appointmentConfirmation", dto, { 'Content-Type': 'application/json' });
}

/* Storage */

/**
 * Uploads a file to the storage location
 *
 * @param {int} documentUploadId
 * @param {File[]} fileList
 * @param {object} formData
 * @param {int} maxFileSize
 * @param {int} mwcSourceId
 * @returns {Function}
 */
export async function uploadFile(
  documentUpload,
  fileList,
  formdata,
  maxFileSize = DEFAULT_MAX_UPLOAD_SIZE,
  mwcSourceId,
  applicationType
) {
  const formData = new FormData();
  const convertBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const fileArr = [];
  await Promise.all(
    fileList.map(async (file) => {
      const base64File = await convertBase64(file);
      fileArr.push({
        content: base64File.replace(",", "---"),
        name: file.name,
      });
    })
  );

  if (Object.keys(formdata).length > 0 && formdata.constructor === Object) {
    let detailsMap;

    // Passport/Card data
    if (isPassPort(documentUpload) || isPassportCard(documentUpload)) {
      detailsMap = {
        passportNumber: formdata.Passport_Number.toUpperCase(),
        passportExpirationDate: formdata.Passport_Expiration_Date,
        dateOfBirth: formdata.dob,
      };
    }
    // Birth Certificate data
    if (isBirthCertificate(documentUpload)) {
      detailsMap = {
        stateOfBirth: formdata.State_Of_Birth,
        countyOfBirth: formdata.County_Of_Birth,
        motherMaidenName: formdata.Mother_Maiden_Name.trim(),
        stateFileNumber: formdata.State_File_Number.trim(),
        registrarNumber: formdata.Registrar_Number.trim(),
        gender: formdata.Gender,
      };
    }
    // Data for documents requiring expiration date
    if (documentUpload.requiredExpirationDate) {
      detailsMap = {
        expirationDate: formdata.Expiration_Date
      };
    }

    Object.keys(detailsMap).forEach(
      (key) =>
        (detailsMap[key] == null || detailsMap[key] === "") &&
        delete detailsMap[key]
    );

    formData.append(
      "detailsMap",
      detailsMap && Object.keys(detailsMap).length > 0
        ? JSON.stringify(detailsMap)
        : null
    );
  }

  formData.append("fileObj", JSON.stringify(fileArr));
  formData.append("maxFileSize", maxFileSize);
  formData.append("mwcSourceID", mwcSourceId);

  let url = applicationType !== APPLICATION_TYPE.DV
    ? `/${applicationType}/document/${documentUpload.id}/upload`
    : `/document/${documentUpload.id}/upload`;

  const response = await STORAGE.post(url, formData);

  return response?.data;
}

/**
 * Update uploaded document's document data with extracted fields from AuthenticID
 *
 * @param {int} documentUploadId
 * @param {object} extractedDetails
 */
export async function updateDocumentData(
  documentUploadId,
  catDocId,
  formdata,
  applicationType
) {
  const formData = new FormData();
  let detailsMap;
  if (Object.keys(formdata).length > 0 && formdata.constructor === Object) {
    // Passport/Card data
    if ([1, 2, 61, 62].includes(catDocId)) {
      detailsMap = {
        passportNumber: formdata.Passport_Number.toUpperCase(),
        passportExpirationDate: formdata.Passport_Expiration_Date,
        dateOfBirth: formdata.dob,
      };
    }

    Object.keys(detailsMap).forEach(
      (key) =>
        (detailsMap[key] == null || detailsMap[key] === "") &&
        delete detailsMap[key]
    );
  }
  const url = applicationType !== APPLICATION_TYPE.DV
    ? `/${applicationType}/application/${documentUploadId}/updateData`
    : `/application/${documentUploadId}/updateData`;
  const response = await HTTP.post(url, detailsMap);
  return response?.data;
}

/**
 * Upload an identity document for extraction by AuthenticId
 *
 * @param {int} documentUploadId
 * @param {String} applicationType
 * @returns {Function}
 */

export async function authenticateDocument(
  docUploadId,
  applicationType
) {
  const localData = {
    docUploadId: docUploadId
  };
  const url = applicationType !== APPLICATION_TYPE.DV
    ? `/${applicationType}/application/extract-document`
    : "/application/identity/extract-document";
  const response = await HTTP.post(url, localData);
  return response?.data;
}

/**
 * Upload selfie(s) with transaction ID to call matchSelfie and matchFace
 *
 * @param {String} transactionId
 * @param {int} documentUploadId
 * @param {String} nearSelfieImage
 * @param {String} farSelfieImage
 * @param {Boolean} selfieOnly
 * @returns {Function}
 */
/*
 export async function callMatchSelfieMatchFace(transactionId, documentUploadId, documentType, nearSelfieImage, farSelfieImage, selfieOnly) {
      const localData = {
          'transactionId': transactionId,
          'docUploadId': documentUploadId,
          'documentType':documentType,
          'base64SelfieNearImage': nearSelfieImage.slice(23),
          'base64SelfieFarImage': farSelfieImage.slice(23),
          'selfieOnly': selfieOnly,
      };
      return HTTP.post('/application/identity/match-selfie-match-face', localData).then(
          (response) => response.data
      );
  }
*/
/**
 * Upload selfie(s) to call matchSelfie liveness only
 * @param {String} selfieImage
 * @param {string} applicationType
 * @returns {Function}
 */

export async function callMatchSelfieLivenessOnly(selfieImage, applicationType, captureMode) {
  const localData = {
    base64SelfieNearImage: selfieImage.slice(23),
    captureMode: captureMode,
  };
  const url = applicationType !== APPLICATION_TYPE.DV 
  ? `/${applicationType}/application/match-liveness-only`
  : "/application/identity/match-liveness-only";
  return HTTP.post(url, localData).then((response) => response.data);
}

export async function callContactless() {
  return getData("/application/contactless");
}

export async function callPresenceVerificationOptIn(hasOpted) {
  return postData(`/application/presenceVerificationOptIn`, { hasOpted });
}

export async function searchMDL(payload) {
  return postData(`/mdl/search`, payload);
}

export async function getDeviceWarningMessageFlag() {
  return getData("/config/mobileWarningMsg?deviceType=" + osName);
}

export async function getSelfieConfigurations(applicationType) {
  let url = applicationType !== APPLICATION_TYPE.DV ? `${applicationType}/application/selfieConfigs` : "/application/selfieConfigs";
  return getData(url);
}

export async function iProovRegister(applicationType, body) {
  const url = applicationType !== APPLICATION_TYPE.DV ? `/${applicationType}/application/iproov/register` : "/application/iproov/register";
  return postData(url, body);
}

export async function iProovAuthenticate(applicationType, body) {
  const url = applicationType !== APPLICATION_TYPE.DV ? `/${applicationType}/application/iproov/authenticate` : "/application/iproov/authenticate";
  return postData(`${url}`, body);
}

export async function iProovValidate(applicationType, body) {
  const url = applicationType !== APPLICATION_TYPE.DV ? `/${applicationType}/application/iproov/validate` : "/application/iproov/validate";
  return postData(`${url}`, body);
}
export async function iProovLogErrorMessages(applicationType, body) {
  const url = applicationType !== APPLICATION_TYPE.DV ? `/${applicationType}/application/iproov/log-device-errors` : "/application/iproov/log-device-errors";
  return postData(`${url}`, body);
}

export async function iProovLogCancelledEvent(applicationType, body) {
  const url = applicationType !== APPLICATION_TYPE.DV ? `/${applicationType}/application/iproov/log-cancelled-event` : "/application/iproov/log-cancelled-event";
  return postData(`${url}`, body);
}

export async function refreshDocumentUploads() {
  return STORAGE.post("/document/doc-refresh");
}

export async function getOptedCategory(applicationType, categoryId) {
  const baseUrl = `/document/opted-categories?categoryId=${categoryId}`;
  const url = applicationType !== APPLICATION_TYPE.DV ? `/${applicationType}${baseUrl}` : baseUrl;
  return STORAGE.get(url);
}

export async function postOptedCategory(applicationType, categoryId, optedIn) {
  const baseUrl = `/document/opted-categories?categoryId=${categoryId}&optedIn=${optedIn}`;
  const url = applicationType !== APPLICATION_TYPE.DV ? `/${applicationType}${baseUrl}` : baseUrl;
  return STORAGE.post(url);
}

export async function getPaymentDetails(applicationType) {
  const url = `/${applicationType}/payment-details`;
  return getData(url);
}

export async function postPayment(applicationType) {
  return postData(`/${applicationType}/payment-portal`);
}

// Save appointment details and send confirmation email
export async function postAppointment(appointment) {
  return postData("/application/appointment/save-and-email", appointment)
}

export async function logoutSession() {
  return getData(process.env.REACT_APP_LOGOUT_URL);
}