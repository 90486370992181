import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import {
  IdentityDocumentContextProvider,
  ResidencyDocumentContextProvider,
  ApplicationContextProvider,
  NameChangesContextProvider,
  DocumentUploadsContextProvider,
  SMSContextProvider,
  ModalContextProvider,
  getApplicationData,
  getNameChanges,
  parseReceivedNameChanges,
  ErrorContextProvider,
  getDocumentsByCategory,
  DOCUMENT_CATEGORY_IDENTITY,
  getApplicationDocUploads,
  DOCUMENT_CATEGORY_RESIDENCY,
  DOCUMENT_CATEGORY_RELATIONSHIP,
  DOCUMENT_CATEGORY_NAME_CHANGE,
  FileUploadContextProvider,
  AppointmentContextProvider,
  GoogleAnalyticsContextProvider,
  CatalogsContextProvider,
  EmailContextProvider,
  setLanguage,
  APPLICATION_TYPE,
} from "@ca-dmv-radv/data";
import { PageWrapperContextProvider } from "@ca-dmv/core";
import { Logout } from "@ca-dmv-radv/components";
import App from "./App";
import { store } from "./store";
import routes, { unauthenticatedRoutes } from "./routes";
import isAuthenticatedRoute from "./isAuthenticatedRoute";
import TimeoutHandler from "./TimeoutHandler";
import * as serviceWorker from "./serviceWorker";
// dmv design styles are loaded here
import "@ca-dmv/design-system/dist/css/style.css";
import { handleApplicationError } from "./util";
import renderFlowByBusinessProgram from "./RenderBusinessProgram";

(async () => {
  if (!isAuthenticatedRoute()) {
    ReactDOM.render(
      <React.StrictMode>
        <BrowserRouter>
          <Routes>
            {unauthenticatedRoutes.map(({ path, Component }) => (
              <Route path={path} key={path} element={<Component />} />
            ))}
          </Routes>
        </BrowserRouter>
      </React.StrictMode>,
      document.getElementById("root")
    );
    return;
  }

  const re = /^\/mdl/;
  if (re.test(window.location.pathname && isAuthenticatedRoute())) {
    await renderFlowByBusinessProgram(APPLICATION_TYPE.MDL);
    return;
  }

  if (window.location.pathname.includes("odp") && isAuthenticatedRoute()) {
    await renderFlowByBusinessProgram(APPLICATION_TYPE.ODP);
    return;
  }

  if (window.location.pathname.includes("vdl") && isAuthenticatedRoute()) {
    await renderFlowByBusinessProgram(APPLICATION_TYPE.VDL);
    return;
  }

  if (window.location.pathname.includes("nch") && isAuthenticatedRoute()) {
    await renderFlowByBusinessProgram(APPLICATION_TYPE.NCH);
    return;
  }

  let initialApplication;
  let applicationLanguage;
  let initialNameChanges;
  let initialIdentityDocuments;
  let initialResidencyDocuments;
  let initialNameChangeDocuments;
  let initialRelationshipDocuments;
  let initialDocumentUploads;

  try {
    const { success, ...data } = await getApplicationData(APPLICATION_TYPE.DV);

    if (!success) {
      throw new Error("getApplicationData");
    }

    initialApplication = data;
    applicationLanguage = data?.lang;
    await setLanguage(applicationLanguage);
  } catch (error) {
    handleApplicationError(error, APPLICATION_TYPE.DV);
    return;
  }

  try {
    [
      initialApplication,
      initialNameChanges,
      initialIdentityDocuments,
      initialResidencyDocuments,
      initialNameChangeDocuments,
      initialRelationshipDocuments,
      initialDocumentUploads,
    ] = await Promise.all([
      getApplicationData(APPLICATION_TYPE.DV),
      getNameChanges(APPLICATION_TYPE.DV),
      getDocumentsByCategory(DOCUMENT_CATEGORY_IDENTITY, APPLICATION_TYPE.DV),
      getDocumentsByCategory(DOCUMENT_CATEGORY_RESIDENCY, APPLICATION_TYPE.DV),
      getDocumentsByCategory(
        DOCUMENT_CATEGORY_NAME_CHANGE,
        APPLICATION_TYPE.DV
      ),
      getDocumentsByCategory(
        DOCUMENT_CATEGORY_RELATIONSHIP,
        APPLICATION_TYPE.DV
      ),
      getApplicationDocUploads(APPLICATION_TYPE.DV),
    ]);
  } catch (error) {
    handleApplicationError(error, APPLICATION_TYPE.DV);
  }

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter basename="/dv">
          <ErrorContextProvider>
            <GoogleAnalyticsContextProvider>
              <ApplicationContextProvider
                application={initialApplication}
                applicationType={APPLICATION_TYPE?.DV}
              >
                <EmailContextProvider>
                  <FileUploadContextProvider>
                    <DocumentUploadsContextProvider
                      initialDocumentUploads={initialDocumentUploads.data}
                    >
                      <ModalContextProvider>
                        <SMSContextProvider>
                          <IdentityDocumentContextProvider
                            identityDocuments={initialIdentityDocuments.data}
                          >
                            <NameChangesContextProvider
                              initialNameChangeDocuments={
                                initialNameChangeDocuments.data
                              }
                              nameChanges={parseReceivedNameChanges(
                                initialNameChanges.data,
                                initialApplication
                              )}
                            >
                              <ResidencyDocumentContextProvider
                                initialResidencyDocuments={
                                  initialResidencyDocuments.data
                                }
                                initialRelationshipDocuments={
                                  initialRelationshipDocuments.data
                                }
                              >
                                <PageWrapperContextProvider>
                                  <AppointmentContextProvider>
                                    <CatalogsContextProvider>
                                      <TimeoutHandler
                                        applicationType={APPLICATION_TYPE.DV}
                                      />
                                      <Logout authenticatedRoutes={routes} />
                                      <App routes={routes} />
                                    </CatalogsContextProvider>
                                  </AppointmentContextProvider>
                                </PageWrapperContextProvider>
                              </ResidencyDocumentContextProvider>
                            </NameChangesContextProvider>
                          </IdentityDocumentContextProvider>
                        </SMSContextProvider>
                      </ModalContextProvider>
                    </DocumentUploadsContextProvider>
                  </FileUploadContextProvider>
                </EmailContextProvider>
              </ApplicationContextProvider>
            </GoogleAnalyticsContextProvider>
          </ErrorContextProvider>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
})();

serviceWorker.unregister();
