import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import {
  ApplicationContextProvider,
  DocumentUploadsContextProvider,
  SMSContextProvider,
  ModalContextProvider,
  ErrorContextProvider,
  FileUploadContextProvider,
  GoogleAnalyticsContextProvider,
  setLanguage,
  getApplicationData,
  APPLICATION_TYPE,
  getDocumentsByCategory,
  IdentityDocumentContextProvider,
  DOCUMENT_CATEGORY_MDL_IDENTITY,
  CatalogsContextProvider,
  getApplicationDocUploads,
  AppointmentContextProvider,
  getNameChanges,
  parseReceivedNameChanges,
  NameChangesContextProvider,
  DOCUMENT_CATEGORY_NCH_NAME_TRACING,
} from "@ca-dmv-radv/data";
// dmv design styles are loaded here
import "@ca-dmv/design-system/dist/css/style.css";
import { PageWrapperContextProvider } from "@ca-dmv/core";
import { Logout } from "@ca-dmv-radv/components";
import { handleApplicationError } from "./util";
import { mdlRoutes, nchRoutes, odpRoutes, vdlRoutes } from "./routes";
import TimeoutHandler from "./TimeoutHandler";
import App from "./App";

async function renderFlowByBusinessProgram(applicationType) {
  let initialApplication;
  let applicationLanguage;
  let initialNameChanges;
  let initialIdentityDocuments;
  let initialNameChangeDocuments;
  let initialDocumentUploads;
  let routes;
  let nameChangesCategoryType;
  let nameTracingCategory;

  switch (applicationType) {
    case APPLICATION_TYPE.ODP:
      routes = odpRoutes;
      break;
    case APPLICATION_TYPE.VDL:
      routes = vdlRoutes;
      break;
    case APPLICATION_TYPE.NCH:
      routes = nchRoutes;
      nameTracingCategory = DOCUMENT_CATEGORY_NCH_NAME_TRACING;
      break;
    case APPLICATION_TYPE.MDL:
    default:
      routes = mdlRoutes;
  }

  if (applicationType === APPLICATION_TYPE.NCH) {
    nameChangesCategoryType = "nameChange";
  }

  try {
    const { success, ...data } = await getApplicationData(applicationType);

    if (!success) {
      throw new Error("getApplicationData");
    }

    initialApplication = data;
    applicationLanguage = data?.lang;
    await setLanguage(applicationLanguage);
    if (
      [APPLICATION_TYPE.MDL, APPLICATION_TYPE.NCH].includes(applicationType)
    ) {
      initialIdentityDocuments = await getDocumentsByCategory(
        DOCUMENT_CATEGORY_MDL_IDENTITY,
        applicationType
      );
    }
    if (applicationType === APPLICATION_TYPE.NCH) {
      [initialNameChanges, initialNameChangeDocuments] = await Promise.all([
        getNameChanges(applicationType),
        getDocumentsByCategory(nameTracingCategory, applicationType),
      ]);
    }
    initialDocumentUploads = await getApplicationDocUploads(applicationType);
  } catch (error) {
    handleApplicationError(error, applicationType);
    return;
  }

  function RouteHelper() {
    return (
      <PageWrapperContextProvider>
        <AppointmentContextProvider>
          <CatalogsContextProvider>
            <TimeoutHandler applicationType={applicationType} />
            {applicationType === APPLICATION_TYPE.NCH && (
              <Logout authenticatedRoutes={routes} />
            )}
            <App routes={routes} />
          </CatalogsContextProvider>
        </AppointmentContextProvider>
      </PageWrapperContextProvider>
    );
  }

  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter basename={`/${applicationType}`}>
        <ErrorContextProvider>
          <GoogleAnalyticsContextProvider>
            <ApplicationContextProvider
              application={initialApplication}
              applicationType={applicationType}
            >
              <FileUploadContextProvider>
                <DocumentUploadsContextProvider
                  initialDocumentUploads={initialDocumentUploads?.data}
                  applicationType={applicationType}
                >
                  <ModalContextProvider>
                    <SMSContextProvider>
                      {[APPLICATION_TYPE.MDL, APPLICATION_TYPE.NCH].includes(
                        applicationType
                      ) ? (
                        <IdentityDocumentContextProvider
                          identityDocuments={initialIdentityDocuments?.data}
                        >
                          <NameChangesContextProvider
                            initialNameChangeDocuments={
                              initialNameChangeDocuments?.data
                            }
                            nameChanges={
                              applicationType === APPLICATION_TYPE.NCH
                                ? parseReceivedNameChanges(
                                    initialNameChanges?.data,
                                    initialApplication,
                                    nameChangesCategoryType
                                  )
                                : []
                            }
                            categoryType={nameChangesCategoryType}
                          >
                            <RouteHelper />
                          </NameChangesContextProvider>
                        </IdentityDocumentContextProvider>
                      ) : (
                        <RouteHelper />
                      )}
                    </SMSContextProvider>
                  </ModalContextProvider>
                </DocumentUploadsContextProvider>
              </FileUploadContextProvider>
            </ApplicationContextProvider>
          </GoogleAnalyticsContextProvider>
        </ErrorContextProvider>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
  );
}
export default renderFlowByBusinessProgram;
